$my-transition : transform 300ms ease 0ms,
box-shadow 300ms ease 0ms;
$box-shadow-after : 0px 4px 8px 0px rgba(75, 82, 97, 0.3);

$primary: #006dcc;
$secondary: #1C1D1F;
$secondary-light: #1E6055;
$info: #BFEAFF;
$white: #fff;
$dark: #000;

$light-gray: #999;
$dark-gray: #3E4143;
