@import './styles/variables.scss';

.uploader-react-comp {

  display: flex;

  &.compact {
    flex-direction: column;
    min-width: auto;
    .file-details {
      flex: 0 0;
      margin-left: 0rem;
    }
  }

  .file-details-notice:not(:empty) {
    margin-right: 1rem;
  }

  // custom dropzone message and styles
  .FileDrop {
    flex: 1 1 auto;
    &:hover {
      cursor: pointer;
    }

    i.fa-upload {
      font-size: 3rem;
      margin-bottom: 2rem;
    }

    p {
      font-size: 2rem;
      line-height: 0.4rem;

      small {
        font-size: 1.2rem;
      }
    }
  }


  /* (mostly default css pulled from file drop) Demo.css */
  .file-drop {
    /* relatively position the container bc the contents are absolute */
    position: relative;
    height: 125px;
    width: 100%;
    border: 1px dashed black;
    color: black;
    padding: 20px;
  }

  .file-drop>.file-drop-target {
    /* basic styles */
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 2px;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;

    /* horizontally and vertically center all content */
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;

    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;

    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;

    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;

    align-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;

    text-align: center;
  }

  .file-drop>.file-drop-target.file-drop-dragging-over-frame {
    /* overlay a black mask when dragging over the frame */
    border: none;
    background-color: #F7F9FA;
    box-shadow: none;
    z-index: 50;
    opacity: 1;

    /* typography */
    color: white;
  }

  .file-drop-target:hover,
  .file-drop-target:focus-within,
  .file-drop>.file-drop-target.file-drop-dragging-over-target {
    /* turn stuff orange when we are dragging over the target */
    color: #A435F0;
    box-shadow: 0 0 13px 3px #A435F0;
  }

  .upload-card {
    justify-content: space-between;
    align-items: center;
    min-width: 0;

    .upload-card-name {
      flex: 1 1 auto;
      font-family: 'Udemy-Sans';
      font-size: 1.2rem;
      font-weight: 400;
      margin: 0 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .close {
      margin-left: 1rem;
    }
  }

  .upload-card-container {
    flex: 1 1 auto;
    min-width: 0;
  }

  .file-details {
    margin-left: 1rem;
    flex: 0 0 fit-content;
    .loader {
      display: none;
    }

    &.loading {
      position: relative;
      .loader {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: fade-out($color: #F7F9FA, $amount: .25);
        font-weight: bold;
        font-size: 2rem;
      }
    }
    text-align: left;
    &-header {
      font-size: 20px;
      font-weight: 500;
    }
    &-action-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
