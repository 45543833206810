@import '../styles/variables.scss';

.uploader-react-comp {
  .card {
    transition: $my-transition;
    &.error {
      border-color: #B94A45 !important;
    }
    div {
      >* {
        margin: auto 0;
        line-height: inherit;
      }
      .img-container {
        width: 150px;
      }
      img {
        max-height: 50px;
      }

      p {
        margin: auto 1rem;
      }
    }

    // &:hover,
    // &:focus {
    //   box-shadow: $box-shadow-after;
    //   cursor: pointer;
    //   border: 1px solid $primary !important;
    // }
  }
}
